.jmeter-result {
  width: 100%;
  padding: 1vw 0vw;
}

.jmeter-result-header {
  margin-bottom: 2vw;
}

.jmeter-result-header p {
  font-size: 1.12vw;
  color: #00264DB2;
  margin: 0;
}

.jmeter-stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5vw;
  margin-top: 2vw;
}

.stat-card {
  background: white;
  border: 1px solid #DDDDDD;
  border-radius: 0.56vw;
  padding: 1.5vw;
  text-align: center;
  box-shadow: 0px 4px 10px 0px #0000001A;
  transition: transform 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
}

.stat-card h3 {
  color: #20264D;
  font-size: 2vw;
  margin: 0;
  margin-bottom: 0.5vw;
  font-weight: 600;
}

.stat-card p {
  color: #00264DB2;
  font-size: 1vw;
  margin: 0;
}

.stat-card.success h3 {
  color: #28a745;
}

.stat-card.error h3 {
  color: #dc3545;
}

.jmeter-charts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
  margin-top: 3vw;
}

.chart-container {
  background: white;
  border: 1px solid #DDDDDD;
  border-radius: 0.56vw;
  padding: 1.5vw;
  box-shadow: 0px 4px 10px 0px #0000001A;
}

.chart-container h4 {
  color: #20264D;
  font-size: 1.12vw;
  margin-bottom: 1.5vw;
  text-align: center;
} 