/* Sponsors section */
.sponsors-section {
  position: relative;
  background-image: url('../../assets/sponsorsbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
}
.sponsors-section img {
  padding: 2.5vw 9.72vw;
  width: 100%;
}

/* Team section */
.about-team-section {
  padding: 5.69vw 11.45vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.team-content {
  width: 40vw;
  padding: 6.5vw 0px;
}
.team-content h4 {
  font-weight: 500;
  font-size: 2.5vw;
  line-height: 120%;
  color: #00264D;
}
.team-content p {
  font-weight: 400;
  font-size: 1.12vw;
  color: rgba(0, 38, 77, 0.7);
}

.about-team-section img {
  width: 30.97vw;
  height: 31.73vw;
}

/* Supercharger QA Teams section */
.supercharger-qa-teams-section {
  padding: 1.8vw 11.14vw 3.6vw 11.14vw;
  background: linear-gradient(0deg, rgba(59, 184, 121, 0.9) -76%, #00264D 50%);
  justify-content: space-between !important;
}
.supercharger-qa-teams-section h3 {
  margin-top: 3.6vw !important;
  width: 34.4vw;
  font-weight: 500;
  font-size: 2.5vw !important;
  line-height: 120%;
  color: #FEFEFE;
}
.supercharger-qa-teams-section p {
  font-weight: 400;
  font-size: 1.39vw !important;
  color: #FEFEFE;
  margin-top: 1vw !important;
}
.supercharger-qa-teams-section a {
  text-align: center;
  box-sizing: border-box;
  padding: 0.83vw 1.67vw;
  background: #FEFEFE;
  border-radius: 1.8vw;
  font-weight: 500;
  font-size: 1.12vw;
  letter-spacing: -0.02em;
  color: #3BB879;
  text-decoration: none;
  margin-top: 2.2vw;
}
.supercharger-qa-teams-actions {
  position: relative !important;
  display: grid;
  min-height: 24.4vw;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4;
  border: none;
}

.supercharger-qa-teams-actions > p {
  margin: auto !important;
  border: none;
  font-size: 1.12vw !important;
  box-sizing: border-box;
  border-radius: 4px;
  backdrop-filter: blur(0px);
  background: transparent;
  color: #FEFEFE;
  text-decoration: none;
  cursor: default;
}

.supercharger-qa-teams-actions > p:hover {
  color: #3BB879;
}

.supercharger-qa-teams-actions .horizontal-line {
  position: absolute !important;
  width: 100%;
  height: 1px !important;
  top: 12.2vw;
  left: 0vw;
  background-image: linear-gradient(90deg, #0D2832 0%, #2DDB81 100%);
  background-repeat: no-repeat;
  transform: rotate(-180deg);
  z-index: 10;
}
.supercharger-qa-teams-actions .vertical-line {
  position: absolute;
  width: 24.4vw;
  height: 1px !important;
  left: 0vw;
  top: 12.2vw;
  background-image: linear-gradient(90deg, #0D2832 0%, #2DDB81 100%);
  transform: rotate(90deg);
  z-index: 10;
}

