.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 1040;
}

.modal.show {
  display: block;
  z-index: 1050;
}

.modal-dialog {
  margin-top: 20vh !important;
}

.modal-content {
  border-radius: 0.56vw;
  border: none;
  box-shadow: 0px 4px 10px 0px #0000001A;
}

.modal-header {
  border-bottom: 1px solid #DDDDDD;
  padding: 1.12vw 1.8vw;
  background: #F5F5F5;
  border-radius: 0.56vw 0.56vw 0 0;
}

.modal-title {
  font-size: 1.12vw;
  color: #00264D;
  margin: 0;
}

.modal-body {
  padding: 1.8vw;
}

.form-group label {
  font-size: 0.973vw;
  color: #00264D;
  margin-bottom: 0.5vw;
}

.form-group textarea {
  border: 1px solid #DDDDDD;
  border-radius: 0.4vw;
  font-size: 0.97vw;
  color: #00264D99;
  padding: 0.9vw;
}

.form-group textarea:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #DDDDDD;
}

.modal-footer {
  border-top: 1px solid #DDDDDD;
  padding: 1.12vw 1.8vw;
}

.modal-footer button {
  border-radius: 30px;
  padding: 0.3vw 1.35vw;
  font-size: 0.97vw;
}

.btn-secondary {
  color: #20264D !important;
  font-weight: 500 !important;
  border: 1px solid #20264D !important;
  background-color: white !important;
}

.btn-primary {
  background-color: #20264D !important;
  color: white !important;
  border: none !important;
}