.multiple-browser-test-result {
  padding: 2vw;
  background-color: white;
  border-radius: 0.556vw;
}

.test-result-header {
  margin-bottom: 2vw;
}

.test-result-header p {
  font-size: 1.111vw;
  color: #00264D;
  margin: 0;
}

.test-stats-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.389vw;
  margin-bottom: 2.778vw;
}

.stat-card {
  background: #F8F9FA;
  border-radius: 0.556vw;
  padding: 1.389vw;
  text-align: center;
}

.stat-card h3 {
  margin: 0;
  font-size: 1.667vw;
  color: #00264D;
  font-weight: 600;
}

.stat-card p {
  margin: 0.347vw 0 0;
  font-size: 0.972vw;
  color: #526683;
}

.stat-card.success h3 {
  color: #28a745;
}

.stat-card.error h3 {
  color: #dc3545;
}

.test-charts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.389vw;
}

.chart-container {
  background: #F8F9FA;
  border-radius: 0.556vw;
  padding: 1.389vw;
}

.chart-container h4 {
  margin: 0 0 1.389vw;
  font-size: 1.111vw;
  color: #00264D;
  font-weight: 500;
}
