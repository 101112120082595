.urlinput-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 2.5vw;
  gap: 0.4vw;
}
.urlinput-form p {
  margin: 0px;
  font-size: 1vw;
  font-weight: 500;
}
.urlinput-form input {
  min-width: 30vw;
  width: 100%;
  height: 2.75vw;
  border-radius: 0.4vw;
  border: 1px solid #DDDDDD;
  padding-left: 1vw;
  color: #00264D99;
  font-size: 1vw;
}
.urlinput-form input:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #DDDDDD !important;
}
.urlinput-form input::placeholder {
  font-size: 1vw;
}

.urlinput-form button {
  height: 2.7vw;
  width: fit-content;
  border-radius: 0.4vw;
  border: none;
  color: white;
  background-color: #20264D;
  font-size: 1vw;
  font-weight: 500;
  padding: 0 2vw;
}

#progress-bar {
  margin: 0px !important;
}

.wait-text {
  color: #526683;
  margin: 0px;
  font-size: 1.12vw;
  margin-left: 1vw;
}
