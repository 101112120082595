/* Quality Engineering Team Content */
.quality-engineering-team-container {
  position: relative;
  margin-top: 1.5vw;
  height: 45.7vw;
}

.quality-engineering-team-ellipse1 {
  box-sizing: border-box;
  position: absolute;
  background-image: url('../../assets/ellipse1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
  overflow: hidden;
  left: 0%;
  right: 0%;
  height: 45.7vw;
  /* top: 5vh; */
  z-index: 2;
  animation: slide-up 1.5s forwards;
}
@keyframes slide-up {
  0% {
    top: 15vh;
  }
  100% {
    top: 0px;
  }
}

.quality-engineering-team-ellipse2 {
  box-sizing: border-box;
  position: absolute;
  background-image: url('../../assets/ellipse2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
  overflow: hidden;
  /* Ellipse 3206 */
  height: 36.5vw;
  left: 10%;
  right: 10%;
  /* top: 134px; */
  top: 9.2vw;
  z-index: 3;
}

.quality-engineering-team-ellipse3 {
  box-sizing: border-box;
  position: absolute;
  background-image: url('../../assets/ellipse2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
  overflow: hidden;
  /* Ellipse 3206 */
  height: 24.3vw;
  top: 21.2vw;
  z-index: 6;
  left: 23.5%;
  right: 23.5%;
  opacity: 1;
}

.quality-engineering-team-selected-agent {
  box-sizing: border-box;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
  overflow: hidden;
  height: 446px;
  z-index: 5;
  right: 23.82%;
  width: 249px;
  /* gap: 0px; */

  /* Ellipse 3211 */

  width: 32.1vw;
  height: 32.1vw;
  left: calc(50% - 32.1vw/2 - 0.5px);
  top: -4.79vw;
}

.quality-engineering-team-selected-agent-content {
  position: relative;
  width: 33.5vw;
  bottom: -12.1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 7;
}

.quality-engineering-team-selected-agent-content h4 {
  /* Performance Engineer */
  box-sizing: border-box;
  width: 17.36vw;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.67vw;
  line-height: 120%;
  text-align: center;
  color: #00264d;
  margin: 0px;
}

.quality-engineering-team-selected-agent-content p {
  margin: 1.2vw 0px;
  box-sizing: border-box;
  width: 33.5vw;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  text-align: center;
  color: rgba(0, 38, 77, 0.7);
}

.quality-engineering-team-selected-agent-content button {
  box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3.34vw;
  width: 26.8vw;
  background: #00264D;
  border-radius: 28px;

  text-align: center;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.39vw;
  letter-spacing: -0.02em;
  color: #FEFEFE;
  border: none !important;
}
.quality-engineering-team-selected-agent-content button:hover {
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.3s ease-in-out;
}

.quality-engineering-team-semicircle-bottom1 {
  box-sizing: border-box;
  position: absolute;
  height: 36.5vw;
  top: 9.2vw;
  z-index: 4;
  left: 10%;
  right: 10%;
  background-image: url('../../assets/semicircle-bot.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
  overflow: hidden;

  backdrop-filter: blur(5px);
  mask: linear-gradient(to top, rgb(255, 255, 255), rgb(255, 255, 255), transparent);
}

.quality-engineering-team-vector1 {
  /* Vector 1 */
  position: absolute;
  width: 30vw;
  height: 18.02vw;
  left: 12.08vw;
  top: 5.3vw;

  z-index: 4;
  background-image: url('../../assets/vector1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
}

.quality-engineering-team-vector2 {
  /* Vector 2 */
  position: absolute;
  width: 30vw;
  height: 18.02vw;
  right: 12.08vw;
  top: 5.3vw;
  z-index: 4;
  background-image: url('../../assets/vector2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
}

.quality-engineering-team-agent1 {
  position: absolute;
  width: 13.29vw;
  height: 13.29vw;
  left: 4.86vw;
  top: 25.48vw;
  /* top: 663.08px; */
  background: #fefefe;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border-radius: 50%;
  z-index: 4;
}
.quality-engineering-team-agent1:hover, .quality-engineering-team-agent2:hover, .quality-engineering-team-agent3:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.quality-engineering-team-agent1-desc {
  position: absolute;
  width: 10.62vw;
  height: 4.23vw;
  left: 12vw;
  top: 19.93vw;
  background: #fefefe;
  box-shadow: -20px 20px 20px 2px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.quality-engineering-team-agent1-desc p {
  width: 7.2vw;
  height: 2.7vw;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: .97vw;
  margin: auto;
  color: rgba(0, 38, 77, 0.7);
}

.quality-engineering-team-agent2 {
  position: absolute;
  width: 191.48px;
  height: 191.48px;
  left: 624.08px;
  /* top: 264.08px; */
  background: #fefefe;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
  overflow: hidden;
  border-radius: 50%;
  z-index: 4;
}

.quality-engineering-team-agent2-desc {
  position: absolute;
  width: 153px;
  height: 61px;
  left: 831px;
  /* top: 299px; */
  background: #fefefe;
  box-shadow: -20px -20px 20px 2px rgba(0, 0, 0, 0.04);
  border-radius: 20px;

  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.quality-engineering-team-agent2-desc p {
  width: 104px;
  height: 40px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: auto;
  color: rgba(0, 38, 77, 0.7);
}

.quality-engineering-team-agent3 {
  position: absolute;
  width: 13.29vw;
  height: 13.29vw;
  right: 4.86vw;
  top: 25.48vw;
  /* top: 662px; */
  background: #fefefe;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
  overflow: hidden;
  border-radius: 50%;
  z-index: 4;
}

.quality-engineering-team-agent3-desc {
  position: absolute;
  width: 10.62vw;
  height: 4.23vw;
  right: 12vw;
  top: 19.93vw;
  /* top: 588px; */
  background: #fefefe;
  box-shadow: -20px -20px 20px 2px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.quality-engineering-team-agent3-desc p {
  width: 6.5vw;
  height: 2.7vw;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: .97vw;
  margin: auto;
  color: rgba(0, 38, 77, 0.7);
}


/* Sponsors section */
.sponsors-section {
  position: relative;
  background-image: url('../../assets/sponsorsbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
}
.sponsors-section img {
  padding: 2.5vw 9.72vw;
  width: 100%;
}


/* live AI builders / intraining section */
.live-aibuilders-in-training-section {
  position: relative;
  width: 100%;
  height: fit-content;
  background: radial-gradient(119.5% 185.87% at 50% 197%, rgba(102, 204, 153, 0.4) 0%, rgba(252, 252, 252, 0.8) 100%);
}
.live-intraining-options {
  box-sizing: border-box;
  margin-top: 5vw;
  width: 41.67vw;
  height: 5.56vw;
  background: #FEFEFE;
  border: 1px solid rgba(0, 38, 77, 0.24);
  border-radius: 0.56vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.56vw;
}
.live-intraining-options p {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20.76vw;
  height: 100%;
  margin: 0px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.67vw;
  color: #00264D;
  cursor: pointer;
}

.live-intraining-options p.active:first-child {
  background: #C2EED7;
  border-right: 1px solid rgba(0, 38, 77, 0.24);
  border-radius: 0.56vw 0 0 0.56vw;
}
.live-intraining-options p.active:last-child  {
  background: #C2EED7;
  border-right: none;
  border-radius: 0 0.56vw 0.56vw 0;
}

#agents-container {
  position: relative;
  width: fit-content;
  margin: 5.84vw 0vw !important;
  padding: 0px 16vw !important;
  left: 0px !important;
  /* height: 33.125vw; */
}

#load-more-agents {
  /* Button */
  position: relative;
  box-sizing: border-box;
  width: 16.38vw;
  height: 3.34vw;
  background: transparent;
  border: 1px solid rgba(0, 38, 77, 0.7);
  border-radius: 1.95vw;

  font-weight: 500;
  font-size: 1.12vw;
  line-height: 2.24vw;
  color: rgba(0, 38, 77, 0.7);
  margin-bottom: 5.84vw;
}


/* Clients Testimonials section */
.testimonials-section {
  width: 100%;
  padding: 5.84vw 0px !important;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.testimonial-header {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5vw;
  line-height: 120%;
  text-align: center;
  color: #00264d;
}
.testimonial-description {
  height: 3.34vw;
  margin-top: 4px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  text-align: center;
  color: #00264d;
}

