.test-api-form {
  margin-top: 2.5vw;
  width: 100%;
}

.form-container {
  display: flex;
  gap: 2vw;
}

.basic-details-section {
  flex: 0 0 40%;
}

.conditional-fields-section {
  flex: 1;
}

.form-section {
  padding: 1.389vw;
  border: 1px solid #DDDDDD;
  border-radius: 0.556vw;
  background-color: white;
}

.form-group {
  margin-bottom: 1.042vw;
}

.form-group label {
  display: block;
  margin-bottom: 0.347vw;
  font-size: 0.972vw;
  color: #00264D;
  font-weight: 500;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="password"],
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.833vw;
  border: 1px solid #DDDDDD;
  border-radius: 0.278vw;
  font-size: 0.972vw;
  color: #00264D99;
}

.load-configs-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2vw;
}
.form-group input[type="number"] {
  padding: 0.5vw 0.833vw !important;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #20264D !important;
}

.form-group textarea {
  min-height: 6.944vw;
  resize: vertical;
}

/* Progress bar and error message */
.progress-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.389vw;
}

#progress-bar {
  width: 11.111vw;
  margin: 0;
}

.wait-text {
  color: #526683;
  margin: 0;
  font-size: 1.111vw;
  margin-left: 0.694vw;
}

.error-message {
  margin-top: 0.694vw;
  width: 100%;
  text-align: center;
  color: red;
  font-size: 0.972vw;
  margin-bottom: 0;
}

/* Buttons */
.form-button-wrapper {
  margin-top: 2.778vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.389vw;
}

.form-button-wrapper button {
  padding: 0.556vw 1.389vw;
  border-radius: 2.083vw;
  font-size: 0.972vw;
  display: flex;
  align-items: center;
  gap: 0.417vw;
}

.reset-button {
  color: #00264D99;
  border: 0.035vw solid #00264D99 !important;
  background-color: white;
}

.test-button {
  background: #20264D;
  color: white;
  border: none;
  border-radius: 0.56vw;
  padding: 0.8vw 2vw;
  font-size: 1vw;
  cursor: pointer;
  transition: all 0.3s ease;
}

.test-button:hover {
  background: #2f3b73;
}

.test-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

/* Hover states */
.form-group input:hover,
.form-group textarea:hover,
.form-group select:hover {
  border-color: #20264D;
}

/* Radio buttons */
.radio-group .radio-options {
  display: flex;
  gap: 1.389vw;
  margin-top: 0.347vw;
}

.radio-options label {
  display: flex;
  align-items: center;
  gap: 0.347vw;
  cursor: pointer;
  font-size: 0.972vw;
  margin: 0;
}

.radio-options input[type="radio"] {
  margin: 0;
  cursor: pointer;
  width: 1.111vw;
  height: 1.111vw;
}

/* File input */
input[type="file"] {
  width: 100%;
  padding: 0.833vw;
  border: 1px solid #DDDDDD;
  border-radius: 0.278vw;
  font-size: 0.972vw;
}

/* Placeholder styles */
.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #00264D99;
  font-size: 0.972vw;
}

.conditional-fields-section input[type="file"] {
  padding: 1vw 1vw;
  border: 1px solid #DDDDDD;
  border-radius: 0.56vw;
  width: 100%;
  cursor: pointer;
}

.conditional-fields-section input[type="file"]:hover {
  border-color: #20264D;
}

.required {
  color: #dc3545;
  margin-left: 4px;
}

.error-message {
  color: #dc3545;
  font-size: 0.9vw;
  margin-top: 1vw;
  white-space: pre-line;
}

/* Add these new styles */
.file-upload-container {
  display: flex;
  gap: 1vw;
  align-items: center;
}

.file-input-wrapper {
  flex: 1;
}

.upload-button {
  padding: 0.833vw 1.389vw;
  background: #20264D;
  color: white;
  border: none;
  border-radius: 0.278vw;
  font-size: 0.972vw;
  cursor: pointer;
  transition: all 0.3s ease;
  height: fit-content;
  white-space: nowrap;
}

.upload-button:hover {
  background: #2f3b73;
}

.upload-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

/* Update existing file input styles */
.file-upload-container input[type="file"] {
  margin: 0;
}

/* Browser Selection Styles */
.browser-section {
  margin-bottom: 1.389vw;
}

.browser-section h5 {
  margin-bottom: 0.347vw;
  font-size: 0.972vw;
  color: #00264D;
  font-weight: 500;
}

.checkbox-group {
  display: flex;
  gap: 1.389vw;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.417vw;
  cursor: pointer;
  font-size: 0.972vw;
  color: #00264D99;
}

.checkbox-label input[type="checkbox"] {
  cursor: pointer;
  width: 1.111vw;
  height: 1.111vw;
}

.checkbox-label:hover {
  color: #20264D;
}

.checkbox-label input[type="checkbox"]:hover {
  border-color: #20264D;
}

.upload-button-content {
  display: flex;
  align-items: center;
  gap: 0.5vw;
}

.loader {
  width: 1vw;
  height: 1vw;
  border: 0.15vw solid #ffffff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
