/* agent-profile.css */
.agent-profile-card {
  /* margin-right: 3.5vw; */
  width: 16vw !important;
  border-radius: 8px;
  padding: 0px !important;
}
.agent-profile-card:last-child {
  margin-right: 0px !important;
}

.agent-profile-img {
  width: 15vw;
  height: 15vw;
  padding: 0px;
  margin: 0px;
  object-fit: cover;
  border: 1px solid #3BB879;
  border-radius: 50%;
  background: #FEFEFE;
}
.agent-profile-img:hover {
  border: 4px solid #3BB879;
}
.in-training-agent-profile-img {
  background: #00000033;
}

.agent-profile-info {
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  text-align: center;
}
.agent-profile-info p {
  margin: 0px;
}

.agent-profile-name {
  width: 17.57vw;
  font-weight: 600;
  font-size: 1.12vw;
  text-align: center;
  color: #00264D;
}

.agent-profile-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.agent-profile-rating img {
  width: 1.7vw;
  height: 1.52vw;
}

.agent-profile-rating,
.agent-profile-executions {
  margin: 8px 0 !important;
}

.agent-profile-executions {
  font-weight: 500;
  font-size: 1.12vw;
  line-height: 1.6vw;
  color: rgba(0, 38, 77, 0.7);
}

.agent-profile-card a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vw;
  width: 12vw;
  height: 3vw;
  font-weight: 500;
  font-size: 1.12vw;
  line-height: 2.24vw;
  text-decoration: none;
  color: #fff;
  background: #00264D;
  border-radius: 1.95vw;
}

.join-waitlist-btn {
  background: #FEFEFE !important;
  border: 1px solid #3BB879 !important;
  color: #3BB879 !important;
  margin-top: 0.83vw !important;
}


