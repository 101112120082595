.footer {
  background: linear-gradient(0deg, rgba(59, 184, 121, 0.9) -76%, #00264D 67.34%);
}

.footer-content {
  padding: 4.65vw 9.16vw 3.34vw 9.16vw;
}
.footer-left-content p, .copyright {
  box-sizing: border-box;
  padding: 0.83vw;
  margin: 0px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  color: #FEFEFE;
}
.footer-left-content img {
  width: 1.67vw;
  height: 1.67vw;
  cursor: pointer;
}
.featured-badge {
  width: 15vw !important;
  height: fit-content !important;
  object-fit: contain !important;
  cursor: pointer;
}

.company-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.13vw !important;
}
.company-name::after {
  content: '';
  position: absolute;
  width: calc(27.5vw + 4.65vw);
  height: 4vw;
  left: -4.65vw;
  background: #FEFEFE1A;
  border-radius: 0 4vw 4vw 0;
}
.company-name img {
  height: 1.89vw;
  width: 2.23vw;
  margin-left: 0.4vw;
  cursor: pointer;
}

.footer-right-content {
  column-gap: 1.5vw;
}
.footer-right-content h4 {
  /* button */
  box-sizing: border-box;
  padding: 0.83vw 1.67vw;

  font-weight: 600;
  font-size: 1.39vw;
  line-height: 1.67vw;
  color: #FEFEFE;
}
.footer-right-content a {
  box-sizing: border-box;
  padding: 0.83vw 1.67vw;
  text-decoration: none;
  font-weight: 300;
  font-size: 1.12vw;
  line-height: 1.67vw;
  color: #FEFEFE;
}
.footer-right-content a:hover {
  /* text-decoration: underline; */
}

.copyright {
  word-spacing: 2px;
  padding: 1.7vw 4.5vw 1.7vw 9.16vw;
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  border-top: 1px solid #FEFEFE66;
}
.copyright p {
  display: flex;
  align-items: center;
  font-size: 1.25vw !important;
}
.copyright a {
  color: #FEFEFE;
  margin: 0px 0.5vw;
  text-decoration: none;
}
.copyright a:hover {
  text-decoration: underline;
}
