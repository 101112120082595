.terms-of-use-section {
  padding: 4vw 9vw;
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 0.25vw;
}

.terms-of-use-section > h2 {
  font-weight: 600;
  font-size: 1.5vw;
  margin-top: 0.7vw;
}
.terms-of-use-section h3 {
  font-weight: 600;
  font-size: 1.25vw !important;
  margin-top: 0.5vw;
}

.terms-of-use-section > p {
}

