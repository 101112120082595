.ui-automation-result {
  width: 100%;
  padding: 1vw 0vw;
  background: white;
}

.ui-automation-result-header {
  margin-bottom: 2vw;
}

.ui-automation-result-header p {
  color: #00264DB2;
  font-size: 1.12vw;
  margin: 0;
}

.download-button {
  background: #20264D;
  color: white;
  border: none;
  border-radius: 0.56vw;
  padding: 0.5vw 1vw;
  cursor: pointer;
}

.download-button:hover {
  background: #2f3b73;
}

.ui-automation-result-grid {
  display: grid;
  grid-template-columns: 300px 1fr;
  padding: 1vw;
  gap: 1.5vw;
  margin-top: 2vw;
  border: 1px solid #DDDDDD;
  border-radius: 1vw;
}

.file-explorer {
  border-right: 1px solid #DDDDDD;
  padding-right: 1vw;
}
.file-explorer h4 {
  color: #20264D;
  font-size: 1.4vw;
}

.file-explorer-description {
  margin: 0;
  padding-bottom: 0.2vw !important;
  border-bottom: 1px solid #e4e4e4;
  font-size: 0.87vw;
}
.file-list {
  margin-top: 1vw;
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  font-size: 1vw;
  cursor: pointer;
  font-weight: 500;
}
.file-list ul {
  list-style-type: none;
  padding-left: 0.7rem !important;
  margin-top: 4px;
}
ul li {
  margin-bottom: 5px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.file-item {
  display: flex;
  align-items: center;
  gap: 0.5vw;
}
.file-item .selected, .file-item:hover {
  color: #2f3b73;
}


.code-area {
  height: 60vh;
  max-height: 60vh;
  overflow: auto;
  margin-top: 0.5vw;
}
.code-area :active, .code-area :focus {
  outline: none;
}

.error-message {
  color: red;
  font-size: 1vw;
  text-align: center;
} 