/* Reuse the existing styles from LoadTestForm.css and WebappTestForm.css */
.test-api-form {
  margin-top: 2.5vw;
  width: 100%;
}

.form-container {
  display: flex;
  gap: 2vw;
}

.basic-details-section {
  flex: 0 0 40%;
}

.conditional-fields-section {
  flex: 1;
}

.form-section {
  padding: 1.389vw;
  border: 1px solid #DDDDDD;
  border-radius: 0.556vw;
  background-color: white;
  margin-bottom: 1.389vw;
}

.form-group {
  margin-bottom: 1.042vw;
}

.form-group label {
  display: block;
  margin-bottom: 0.347vw;
  font-size: 0.972vw;
  color: #00264D;
  font-weight: 500;
}

.form-group input[type="text"],
.form-group input[type="password"],
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.833vw;
  border: 1px solid #DDDDDD;
  border-radius: 0.278vw;
  font-size: 0.972vw;
  color: #00264D99;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #20264D !important;
}

.form-group textarea {
  min-height: 6.944vw;
  resize: vertical;
}

/* File upload styles */
.file-upload-container {
  display: flex;
  gap: 1vw;
  align-items: center;
}

.file-input-wrapper {
  flex: 1;
}

.upload-button {
  padding: 0.833vw 1.389vw;
  background: #20264D;
  color: white;
  border: none;
  border-radius: 0.278vw;
  font-size: 0.972vw;
  cursor: pointer;
  transition: all 0.3s ease;
  height: fit-content;
  white-space: nowrap;
}

.upload-button:hover {
  background: #2f3b73;
}

.upload-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

/* Form buttons */
.form-button-wrapper {
  margin-top: 2.778vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.389vw;
}

.reset-button {
  display: flex;
  align-items: center;
  gap: 0.417vw;
  padding: 0.556vw 1.389vw;
  border-radius: 2.083vw;
  font-size: 0.972vw;
  color: #00264D99;
  border: 0.035vw solid #00264D99 !important;
  background-color: white;
  cursor: pointer;
}

.test-button {
  background: #20264D;
  color: white;
  border: none;
  border-radius: 0.56vw;
  padding: 0.8vw 2vw;
  font-size: 1vw;
  cursor: pointer;
  transition: all 0.3s ease;
}

.test-button:hover {
  background: #2f3b73;
}

.test-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

/* Error message */
.error-message {
  color: #dc3545;
  font-size: 0.9vw;
  margin-top: 1vw;
  white-space: pre-line;
}

.required {
  color: #dc3545;
  margin-left: 4px;
}

/* Progress indicator */
.progress-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.389vw;
}

#progress-bar {
  width: 11.111vw;
  margin: 0;
}

.wait-text {
  color: #526683;
  margin: 0;
  font-size: 1.111vw;
  margin-left: 0.694vw;
} 