body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(
    100.55% 156.4% at 50% -116.06%,
    #fcfcfc 0%,
    rgba(102, 204, 153, 0.1) 100%
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: start;
  width: 100%;
  font-family: 'DM Sans';
  font-style: normal;
  scroll-behavior: smooth;
}

.page-header {
  margin-top: 2.5vw;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 2.78vw;
  line-height: 120%;
  text-align: center;
  color: #00264d;
}
.page-subheader {
  width: 40.34vw;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.3vw;
  text-align: center;
  color: rgba(0, 38, 77, 0.7);
  margin-bottom: 5vw !important;
}
.page-subheader span {
  font-family: DM Sans;
  font-weight: 700;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
