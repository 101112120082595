
.carousel-inner {
  width: 48.34vw !important;
}
.carousel-indicators {
  position: relative !important;
  margin-top: 3.34vw;
}
.carousel-indicators button {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50%;
  border: 1px solid #00264D66 !important;
}
.carousel-indicators button.active {
  background: #00264D66 !important;
}
.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.client-image {
  height: 2vw;
  /* border-radius: 50%; */
  margin-top: 1vw;
  margin-bottom: 1.5rem;
}
.client-name {
  font-weight: 700;
  font-size: 1.12vw;
  line-height: 1.5vw;
  text-align: center;
  color: #00264D;
  margin: 0px;
}
.client-position {
  font-size: 0.9vw;
  font-weight: 400;
  line-height: 1.12vw;
  color: #00264D;
  margin: 2px 0px;
  text-align: center;
}
.client-testimonial {
  font-weight: 400;
  font-size: 1.12vw;
  line-height: 1.5vw;
  text-align: center;
  color: #00264D;
  margin: 0px;
  margin-top: 1.12vw;
}
