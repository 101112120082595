.verify-a-webiste-result-filter {
  height: 2.3vw;
  width: fit-content;
  border-radius: 0.4vw;
  border: 1px solid #20264D;
  color: #20264D;
  background-color: white;
  font-size: 1vw;
  font-weight: 500;
  padding: 0 1.5vw;
}

.verify-a-webiste-result-filter.active, .verify-a-webiste-result-filter:hover {
  height: 2.3vw;
  width: fit-content;
  border-radius: 0.4vw;
  border: 1px solid #20264D;
  color: white;
  background-color: #20264D;
  font-size: 1vw;
  font-weight: 500;
  padding: 0 1.5vw;
}

.verify-a-webiste-result-table {
  margin-top: 1.4vw;
}

.verify-a-webiste-result-table > * {
  text-transform: capitalize;
  text-align: start;
  font-size: 1.12vw;
}
.verify-a-webiste-result-table > thead > tr > th {
  background-color: #20264D;
  color: white;
  border: none;
  font-size: 1.12vw;
  padding: 1vw !important;
}
.verify-a-webiste-result-table > tbody > tr > td img {
  width: 2.2vw;
  height: 2.2vw;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.4vw;
  margin-top: -0.35vw;
}
.verify-a-webiste-result-table > tbody > tr > td {
  padding: 1.75vw 1vw !important;
  font-size: 1vw;
}
