.privacy-policy-section {
  padding: 4vw 7vw;
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 0.25vw;
}

.privacy-policy-section > h2 {
  font-weight: 600;
  font-size: 1.5vw;
}

.privacy-policy-section > p {
}

