.agent-activate-section {
  margin: 4vw;
  padding: 3.5vw;
  border-radius: 2vw;
  background-color: white;
  box-shadow: 0px 4px 10px 0px #0000001A;
}
.agent-activate-section h6 {
  font-size: 1.12vw;
}

.agent-image {
  width: 6.3vw;
  height: 6.3vw;
  border: 2px solid #00264D3D;
  background-color: #20264d1b;
  border-radius: 50%;
  object-fit: cover;
}
.agent-name {
  margin: 0px;
  margin-right: 1vw;
  font-size: 1.39vw;
  width: fit-content !important;
}
.follow-button {
  border: 1px solid #20264D;
  color: #20264D;
  background-color: white;
  border-radius: 0.973vw;
  padding: 0.4vw 2.5vw;
  font-size: 0.973vw;
  margin-left: 2vw;
}
.agent-description {
  font-size: 1.12vw;
  color: #00264DB2;
}

/* agent form */
.agent-form {
  margin-top: 2.5vw;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2vw 25vw;
  gap: 3vw;
  /* center grid items horizontally */
}

.agent-textarea-wrapper, .agent-req-upload-wrapper {
  border-radius: 0.56vw;
  display: flex;
  flex-direction: column;
  border: 1px solid #DDDDDD;
}
.agent-textarea-label, .agent-req-upload-label {
  border-radius: 0.56vw 0.56vw 0px 0px !important;
  font-size: 0.973vw;
  color: #00264D;
  line-height: 1.25vw !important;
  background: #F5F5F5;
  margin: 0px;
  padding: 1.12vw 1.8vw;
  border-bottom: 1px solid #DDDDDD !important;
}
.agent-textarea {
  border-radius: 0px 0px 0.56vw 0.56vw !important;
  font-size: 0.97vw !important;
  border: none !important;
  resize: none;
  color: #00264D99 !important;
  font-weight: 400 !important;
  padding: 0.9vw 0.9vw 0 0.9vw;
}
.agent-textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #DDDDDD !important;
  border-radius: 0px 0px 0.56vw 0.56vw !important;
}

.agent-from-or {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.35vw;
  color: #20264D;
  font-weight: 600;
}

.agent-req-upload-wrapper {
}
.agent-req-upload {
  gap: 0.5vw;
  height: 100%;
}
.agent-req-upload p {
  margin: 0px;
  font-size: 0.83vw;
  color: #00264DB2;
  cursor: default;
}
.custom-file-upload {
  color: #003366;
  font-size: 0.83vw;
  font-weight: 500;
  cursor: pointer;
}
.file-input {
  display: none;
}

.error-message {
  margin-top: 1vw;
  width: 100%;
  text-align: center;
  color: red;
  margin-bottom: 0px;
}

.agent-button-wrapper button {
  color: #00264D99;
  border: 0.5px solid #00264D99 !important;
  border-radius: 30px;
  padding: 0.3vw 1.35vw;
  border: none;
  align-self: center;
  font-size: 0.97vw;
  background-color: white;
}
.generate-button {
  min-width: 60px !important;
  background-color: #20264D !important;
  color: white !important;
  border-radius: 30px;
}
#progress-bar {
  margin-top: 1vw;
  width: 11vw;
}

/* Result section */
.agent-result-section {
  box-shadow: 0px 4px 10px 0px #0000001A;
  margin: 4vw;
  margin-top: -1vw;
  padding: 3.5vw;
  border-radius: 2vw;
  background-color: white; 
}

.agent-result-section h5 {
  color: #20264D;
  font-size: 1.39vw;
  font-weight: 600;
}
.agent-result-header-actions {
  display: flex;
  flex-direction: row;
  gap: 1vw;
}
.agent-result-header-actions > * {
  border: 1.5px solid #20264D;
  color: #20264D;
  background-color: white;
  border-radius: 0.56vw;
  padding: 0.3vw;
  height: 2vw;
  width: 2vw;
  cursor: pointer;
}

.agent-result-header-actions > *:hover {
  background-color: #20264D;
  color: white;
}

.action-text-btns {
  padding: 0px 1vw !important;
  width: fit-content;
  font-size: 1vw;
  border: 1.5px solid #20264D !important;
}

.action-text-btns.disabled, .action-text-btns.disabled:hover {
  border: 1.5px solid #b6b6b6 !important;
  cursor: default !important;
  background-color: white !important;
  color: #9a9a9a !important;
}

/* agent-result-table */
.table-responsive {
  max-width: 100%;
}
.agent-result-table {
  margin-top: 1vw;
}

.agent-result-table > * {
  text-transform: capitalize;
  text-align: start;
  font-size: 1.12vw;
}
.agent-result-table > thead > tr > th {
  background-color: #20264D;
  color: white;
  border: none;
  font-size: 1.12vw;
}
.agent-result-table > tbody > tr > td {
  padding: 1vw;
  font-size: 1vw;
}

