
.header {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 1.67vw 3.34vw;
  height: 5.834vw;
  background-color: #EAF9F6;
}
.logo {
  width: 2.91vw;
  height: 2.51vw;
}
.header-title {
  /* Digital Employees by QualityX */
  margin-left: 1.2vw;
  height: 1.67vw;

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  color: #00264D;
}
.nav-pills {
  width: fit-content !important;
}
.nav-item,
.nav-link {
  width: fit-content;
  height: 1.67vw;
  font-family: 'DM Sans' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1.12vw;
  line-height: 1.67vw;
  color: #00264d !important;
  /* padding: 4px 0.7vw; */
}
.nav-link-button {
  color: #00264d !important;
  font-family: 'DM Sans' !important;
  font-style: normal;
  text-decoration: none;
}
.signin-button {
  margin-left: 1.67vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 5.97vw;
  height: 2.7vw;
  border: 1px solid #00264D;
  background: none;
  border-radius: 28px;
  margin-right: 1.67vw;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 0.834vw;
  line-height: 1.67vw;
}
.signup-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 5.97vw;
  height: 2.7vw;
  background: #00264d;
  border: 1px solid #00264d;
  border-radius: 28px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 0.834vw;
  line-height: 1.67vw;
  color: #fefefe;
}
