/* How it works main content */
.how-it-works-content {
  background-color: white;
}

.how-it-works-diagram1-container, .how-it-works-diagram2-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 5.7vw 19.0vw;
  background: radial-gradient(136.65% 212.53% at 50% 197%, rgba(102, 204, 153, 0.4) 0%, rgba(252, 252, 252, 0.8) 100%);
}
.how-it-works-diagram2-container {
  margin-top: 5vw;
  background: white !important;
}

.how-it-works-diagram1-container h1, .how-it-works-diagram2-container h1 {
  font-size: 2.23vw;
}
.how-it-works-diagram1-container p, .how-it-works-diagram2-container p {
  font-size: 1.25vw;
}

.how-it-works-diagram1 {
  width: 51.1vw;
  height: 91.12vw;
}
.how-it-works-diagram2 {
  height: 116.32vw;
  width: 61.8vw;
}

.sign-up-its-free-section h3 {
  font-size: 2.5vw;
}


