.create-automation-form {
  margin-top: 2.5vw;
  width: 100%;
}

.form-section.bordered-container {
  padding: 1.389vw;
  border: 1px solid #DDDDDD;
  border-radius: 0.556vw;
  background-color: white;
}

.form-group {
  margin-bottom: 1.042vw;
}

.form-group label {
  display: block;
  margin-bottom: 0.347vw;
  font-size: 0.972vw;
  color: #00264D;
  font-weight: 500;
}

.form-group input[type="text"],
.form-group input[type="url"],
.form-group textarea {
  width: 100%;
  padding: 0.833vw;
  border: 1px solid #DDDDDD;
  border-radius: 0.278vw;
  font-size: 0.972vw;
  color: #00264D99;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #20264D !important;
}

.form-button-wrapper {
  margin-top: 2.778vw;
  width: 100%;
  display: flex;
  justify-content: center;
}

.test-button {
  background: #20264D;
  color: white;
  border: none;
  border-radius: 0.56vw;
  padding: 0.8vw 2vw;
  font-size: 1vw;
  cursor: pointer;
  transition: all 0.3s ease;
}

.test-button:hover {
  background: #2f3b73;
}

.error-message {
  color: red;
  font-size: 0.972vw;
  margin-top: 0.694vw;
  text-align: center;
}

/* Loader and progress bar styles */
.progress-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.389vw;
}

#progress-bar {
  width: 11.111vw; /* Adjust width as needed */
  margin: 0;
}

.wait-text {
  color: #526683;
  margin: 0;
  font-size: 1.111vw;
  margin-left: 0.694vw;
} 