.live-ai-builders-header {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 2.78vw;
  line-height: 120%;
  text-align: center;
  color: #00264d;
}
.live-ai-builders-description {
  width: 40.34vw;
  height: 3.34vw;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  text-align: center;
  color: rgba(0, 38, 77, 0.7);
  margin-top: 8px;
}
.live-ai-builders-description span {
  font-family: DM Sans;
  font-weight: 700;
  text-align: center;
}


/* AI Builders Content */
.ai-builders-container {
  background: radial-gradient(119.5% 185.87% at 50% 197%, rgba(102, 204, 153, 0.4) 0%, rgba(252, 252, 252, 0.8) 100%);
  padding: 5.56vw 0vw;
}
.live-ai-builders-header {
  font-size: 2.23vw;
  text-align: center;
}

.section-content {
  position: relative;
  margin: 5.84vw 0vw !important;
  padding: 0px 16vw !important;
}
.load-more-agents-btn {
  position: relative;
  box-sizing: border-box;
  width: 16.38vw;
  height: 3.34vw;
  background: transparent;
  border: 1px solid rgba(0, 38, 77, 0.7);
  border-radius: 1.95vw;

  font-weight: 500;
  font-size: 1.12vw;
  line-height: 2.24vw;
  color: rgba(0, 38, 77, 0.7);
  margin-bottom: 5.84vw;
}


