.api-automation-result {
  width: 100%;
  padding: 1vw 0vw;
  background: white;
}

.api-automation-result-header {
  margin-bottom: 2vw;
}

.api-automation-result-header p {
  color: #00264DB2;
  font-size: 1.12vw;
  margin: 0;
}

.download-button {
  background: #20264D;
  color: white;
  border: none;
  border-radius: 0.56vw;
  padding: 0.5vw 1vw;
  cursor: pointer;
}

.download-button:hover {
  background: #2f3b73;
}

.code-area {
  margin-top: 1.5vw;
  height: 60vh;
  overflow: auto;
}

.error-message {
  color: red;
  font-size: 1vw;
  text-align: center;
} 